<template>
    <section class="newsletter">
        <b-container>
            <b-row>
                <b-col cols="12" lg="6" offset-lg="1">
                    <h2 class="mb-3 mb-lg-5">Bądź zawsze na bieżąco!</h2>
                    <p class="mb-5">Jeśli chcesz być na bieżąco z naszymi projektami i działaniami, otrzymywać zaproszenia na ciekawe wydarzenia i wyjątkowe inspiracje - zapraszamy do naszego newslettera!</p>
                    <b-form class="row align-items-center" @submit="handleNewsletterFormSubmit">
                        <div class="col">
                            <div class="row">
                                <b-col class="pr-lg-0" order="1">
                                    <b-input v-model="name" placeholder="Imię" required></b-input>
                                </b-col>
                            </div>
                            <div class="row mt-3">
                                <b-col class="pr-lg-0" order="1">
                                    <b-input v-model="email" placeholder="Email" required></b-input>
                                </b-col>
                            </div>
                        </div>
                        <b-col cols="auto pl-lg-0" order="3" order-lg="2">
                            <b-button type="submit" variant="dark" class="ml-lg-4">Zapisz się</b-button>
                        </b-col>

                        <b-col cols="12" order="2" order-lg="3">
                            <label class="d-flex flex-row">
                                <div class="position-relative">
                                    <input type="checkbox" v-model="required_agreement" required/>
                                    <div class="checkbox"></div>
                                </div>
                              <p>Zgadzam się na przetwarzanie moich danych przez Stowarzyszenie Cyfrowy Dialog w celu realizacji
                                usługi newsletter, a tym samym wysyłania mi informacji dotyczących Stowarzyszenia zgodnie z jego
                                <a href="https://cyfrowydialog.pl/wp-content/uploads/2021/08/polityka-prywatnos%CC%81ci-scd-2.pdf" target="_blank">polityką prywatności</a>
                                oraz informacji o produktach, usługach i nowościach dostępnych na stronie
                                <a href="https://nodn.cyfrowydialog.pl/" target="_blank">nodn.cyfrowydialog.pl</a> zgodnie z
                                <a href="https://nodn.cyfrowydialog.pl/polityka-prywatnosci/" target="_blank">polityką prywatności sklepu internetowego</a>.</p>
                            </label>
                        </b-col>

<!--                        <b-col cols="12" order="2" order-lg="3">-->
<!--                            <label class="d-flex flex-row">-->
<!--                                <div class="position-relative">-->
<!--                                    <input type="checkbox" v-model="optional_agreement" required/>-->
<!--                                    <div class="checkbox"></div>-->
<!--                                </div>-->
<!--                                <p>Wyrażam zgodę na przetwarzanie przez Stowarzyszenie Cyfrowy Dialog moich danych osobowych w celu otrzymywania Oferty handlowej (Przeczytaj: <a href="https://cyfrowydialog.pl/wp-content/uploads/2021/03/Klauzula-informacyjna-zgoda-marketingowa.pdf" target="_blank">klauzula informacyjna</a>).</p>-->
<!--                            </label>-->
<!--                        </b-col>-->
                    </b-form>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    import ApiClient from './../../store/wp_api_client'
    import Swal from 'sweetalert2'
    const api = new ApiClient(process.env.VUE_APP_API_URL)

    export default {
        name: "Newsletter",
        data() {
            return {
                form: null,
                name: null,
                email: null,
                required_agreement: false,
            }
        },
        methods: {
            handleNewsletterFormSubmit(e) {
                e.preventDefault()
                if(!this.email || !this.name) return;
                api.sendNewsletterForm(this.email, this.name)
                    .then(() => {
                        this.email = null
                        this.name = null
                        this.required_agreement = false
                        Swal.fire({
                          text: 'Dziękujemy za zapisanie się do naszego newslettera. Sprawdź pocztę (także folder spam) i potwierdź zapis klikając w link aktywacyjny, który wysłaliśmy do Ciebie mailem.',
                          icon: 'success',
                        })
                    })
                    .catch(() => {
                      Swal.fire({
                        text: 'Twój adres e-mail jest już w naszej bazie newsletterowej.',
                        icon: 'warning',
                      })
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
    section{
        background: linear-gradient(180deg, rgba(250, 250, 250, 0) 0%, #EBECF5 100%);
        padding-top: 80px;
        padding-bottom: 0px;
        overflow: hidden;

        @include media-breakpoint-up(lg){
            padding-top: 80px;
            padding-bottom: 80px;
        }

        .container{
            position: relative;
            padding-bottom: 188px;

            input[type="text"]{
                background: $white;
            }

            &::after{
                content: "";
                position: absolute;
                display: block;
                background-image: url('../../assets/media/newsletter-plane-line.svg');
                bottom: 0;
                left: -60%;
                width: 745px;
                padding-bottom: 60%;
                background-repeat: no-repeat;
                z-index: -1;
            }

            @include media-breakpoint-up(md){
                &::after{
                    left: 0;
                    bottom: -30%;
                }
            }

            @include media-breakpoint-up(lg){
                padding-top: 76px;
                padding-bottom: 102px;
                background-image: url('../../assets/media/newsletter-border-background.svg');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;

                &::after{
                    top: 163px;
                    right: -118px;
                    bottom: initial;
                    left: initial;
                    width: 745px;
                    padding-bottom: 31.275%;
                }
            }
        }

        label{
            position: relative;
            margin-top: 32px;
            margin-bottom: 32px;
            width: 88%;
            cursor: pointer;

            p{
                @extend %header-6;
                margin: 0;
            }

            input{
                opacity: 0;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-100%, -100%);
            }

            .checkbox{
                position: relative;
                width: 28px;
                height: 28px;
                border-radius: 5px;
                background: $white;
                margin-right: 16px;
                transition: 0.2s ease;

                &::after{
                    position: absolute;
                    top: 50%;
                    left: 31%;
                    content: "";
                    width: 8px;
                    height: 14px;
                    transform: rotate(40deg) translate(-50%, -50%) scale(0);
                    border-right: 3px solid $primary;
                    border-bottom: 3px solid $primary;
                    transition: 0.2s ease;
                    transition-delay: 0;
                }
            }

            input:checked + .checkbox{
                background: $primary;

                &::after{
                    transform: rotate(40deg) translate(-50%, -50%) scale(1);
                    border-color: $white;
                    transition-delay: 0.1s;
                }
            }

            @include media-breakpoint-up(lg){
                margin-bottom: 0;
            }
        }
    }
</style>